





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import fileDownload from 'js-file-download';
import pdf from 'vue-pdf';

const FileManagement = namespace('fileManagement');

@Component({
  components: {
    ErrorAlert,
    pdf
  }
})
export default class ViewFileDialog extends Vue {
  @Prop({ type: Blob }) file!: Blob | null;
  @Prop({ type: String }) filename!: string;

  error: any = null;
  dialog: boolean = false;

  loading: boolean = false;
  view: string = '';

  fileTask: { promise: Promise<{ numPages: number }> } | null = null;
  pageCount: number = 0;

  download() {
    if (this.file) {
      fileDownload(this.file, this.filename);
    }
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
    }, 200);

    this.$emit('update:file', null);
  }

  @Watch('file', { immediate: true })
  async watchFile(file: Blob | null) {
    if (!file) {
      this.fileTask = null;
      this.pageCount = 0;
      URL.revokeObjectURL(this.view);

      this.view = '';
      this.$emit('closed');

      return;
    }

    this.view = URL.createObjectURL(file);
    this.fileTask = pdf.createLoadingTask(this.view);

    if (this.fileTask) {
      const { numPages } = await this.fileTask.promise;

      this.pageCount = numPages;
    }

    this.dialog = true;
  }
}
